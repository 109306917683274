<template>
  <div id="Natural">
    <TitleHeader msg="我的资质"></TitleHeader>
    <el-row>
      <el-col :span="24" class="tipsInfo"
        >温馨提示:资质过期账号将被自动锁定,如有更新,请及时上传并完成邮寄,我们将尽快为您审核,您也可以随时联系您的区域顾问</el-col
      >
    </el-row>
    <!-- <el-row class="roleInfo">
      <el-col :span="8" v-for="(item, index) in arr" :key="index">
        <div class="photoBox">
          <el-col :span="8">
            <img :src="item.src" class="roleImg" alt="图片" />
          </el-col>
          <el-col :span="16">
            <el-col :span="24">
              <span class="name">{{ item.name }}</span
              ><span class="phone">{{ item.phone }}</span>
            </el-col>
            <el-col :span="24">
              <span class="role">{{ item.role }}</span>
            </el-col>
          </el-col>
        </div>
      </el-col>
    </el-row> -->

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :data="creditlist"
      class="wraper"
      style="width: 100%;"
    >
      <el-table-column
        class="text-center"
        prop="titleName"
        label="资质名称"
        width="180"
      >
      </el-table-column>
      <el-table-column
        class="text-center"
        prop="certifyingAuthority"
        label="发证机构"
        width="180"
      >
      </el-table-column>

      <el-table-column
        class="text-center"
        prop="validityEndTime"
        label="截止日期"
      >
      </el-table-column>
      <el-table-column class="text-center" prop="statusName" label="业务状态">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onPreview(scope.row)"
            >查看</el-button
          >
          <!-- <el-button style="color: #409EFF;" type="text" size="small"
            >|</el-button
          >
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >更新</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
  </div>
</template>

<script>
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { CreditList, UpdateCredit } from "api/member.js";
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "Natural",
  data() {
    return {
      showViewer: false, // 显示查看器
      url: "",
      input: "",
      loading: false,
      arr: [
        {
          src:
            "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          name: "张三",
          phone: "15110075640",
          role: "内勤",
        },
        {
          src:
            "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          name: "张三rii",
          phone: "15110075640",
          role: "省区经理",
        },
        {
          src:
            "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          name: "张三yufry",
          phone: "15110075640",
          role: "大区总监",
        },
      ],
      creditlist: [],
    };
  },
  methods: {
    onPreview(row) {
      this.showViewer = true;
      this.url = row.image;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    // 获取会员资质信息
    getCreditList: function() {
      CreditList().then((res) => {
        this.creditlist = res.data.data;
      });
    },
    //更新会员资质
    getUpdateCredit: function(data) {
      UpdateCredit(data).then((res) => {
        if (res.data.code == 400) {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        } else {
          this.creditlist = res.data.data;
        }
      });
    },
    search() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
    handleClick(row) {
      const updateImage = "updateImage";
      const updateImageBack = "updateImageBack";
      const data = {
        id: row.id,
        updateImage: updateImage,
        updateImageBack: updateImageBack,
      };
      this.getUpdateCredit(data);
    },
  },
  created() {
    this.getCreditList();
  },
  components: {
    TitleHeader,
    ElImageViewer,
  },
};
</script>

<style>
/* 表格样式 */
#Natural .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff !important;
}
#Natural .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
#Natural .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: none;
}
/* th样式 */
#Natural .el-table th {
  background-color: #eee;
  text-align: center;
}
/* td样式 */
#Natural .el-table .cell {
  text-align: center;
}
</style>

<style lang="less" scoped>
@import "~style/index.less";

.tipsInfo {
  color: #666;
  font-size: 14px;
  padding: 20px;
}
.el-table thead {
  background: red;
}
.roleInfo {
  padding: 10px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  .photoBox {
    box-shadow: 0px 1px 8px #d4d2d2;
    display: inline-block;
    border-radius: 5px;
    padding: 8px 6px;
    width: 90%;
    .roleImg {
      display: inline-block;
      width: 48px;
      height: 48px;
      overflow: hidden;
      margin: 0 auto;
      display: block;
    }
    .name {
      color: #303133;
      font-size: 14px;
    }
    .phone {
      color: #00a9ef;
      font-size: 14px;
      padding-left: 20px;
    }
    .role {
      font-size: 12px;
      color: #888;
    }
  }
}

.el-pagination {
  padding-top: 40px;
  text-align: center;
}
</style>
